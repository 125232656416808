/**
 * AlzTalk Admin
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";

export const defaults: Oazapfts.RequestOpts = {
  baseUrl: "http://localhost:9091/api",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
  localDevelopment: "http://localhost:9091/api",
  officeDevelopmentLan: "http://10.20.50.214:9091/api",
  officeDevelopmentTailscale: "http://vpn-friday.haiidev.co.kr:9091/api",
  gcpDevelopment: "https://azt.haiidev.co.kr/api",
  production: "https://azt.haii.io/api",
  localDevelopmentApiGateway: "http://localhost:8880/azt",
  officeDevelopmentTailscaleApiGateway:
    "http://vpn-friday.haiidev.co.kr:8880/azt",
  gcpDevApiGateway: "https://api.haiidev.co.kr/azt",
  gcpProductionApiGateway: "https://api.haii.io/azt",
};
export type LoginRequest = {
  name?: string;
  email: string;
};
export type PermissionResponse = {
  permissionLevel: number;
  permissionName: string;
  roleLevel: number;
  roleName: string;
};
export type GenericResponse = {
  code: number;
  message: string | object;
  data?: object;
};
export type EntityListResponse = {
  total?: number;
};
export type EntityResponse = {
  UUID: string;
  CTS?: number;
  MTS?: number;
};
export type CompanyResponse = EntityResponse & {
  model: string;
  companyName: string;
};
export type AdminResponse = EntityResponse & {
  adminName: string;
  email: string;
  phone: string;
  comment?: string;
  fbUID?: string;
  company?: CompanyResponse;
};
export type GameGroupResponse = EntityResponse & {
  displayName: string;
  enabledFlag: boolean;
  demoFlag: boolean;
};
export type CouponResponse = EntityResponse & {
  code: string;
  enabledFlag: boolean;
  demoFlag?: boolean;
  admin?: AdminResponse;
  gameGroup?: GameGroupResponse;
};
export type CistResponse = {
  name: string;
  age: number;
  gender: string;
  edu: string;
  eduYear: number;
  kMMSETotal: number;
  kMMSEPercent: number;
  cistTotal: number;
  cistResult: number;
  diagnosis: number;
  amyloidBeta: number;
};
export type UserResponse = EntityResponse & {
  userKey: string;
  displayName: string;
  birthDT: string;
  phone: string;
  gender: string;
  enabledFlag: boolean;
  assessmentFlag: boolean;
  attendanceDay: number;
  coupon?: CouponResponse;
  cist?: CistResponse;
  isSuspectedDementia?: boolean;
  reportUrl?: string;
};
export type GameResponse = EntityResponse & {
  itype: number;
  displayName: string;
  nth: number;
  enabledFlag: boolean;
};
export type HistoryLogResponse = EntityResponse & {
  startTM: string;
  endTM: string;
  formatVer: number;
  detail: string;
  game?: GameResponse;
};
export type HistoryLogListResponse = EntityListResponse & {
  historyLogs: HistoryLogResponse[];
};
export type GamesResponse = {
  gameTypes: number[];
};
export type HistoryResponse = EntityResponse & {
  startTM: string;
  endTM: string;
  completedFlag: boolean;
  user?: UserResponse;
  historyLog?: HistoryLogListResponse;
  games?: GamesResponse;
};
export type HistoryListResponse = EntityListResponse & {
  histories: HistoryResponse[];
};
export type UserRequest = {
  displayName?: string;
  phone?: string;
  birthDT?: string;
  gender?: string;
  edu?: string;
  ethnicity?: string;
  country?: string;
  countryCode?: string;
  startTM?: string;
  couponUUID?: string;
  deviceID?: string;
};
export type HistoryExcelUploadRequest = {
  startDT: string;
  endDT: string;
  file: Blob;
  sheet: string;
};
export type HistoryExcelUploadResponse = {
  total: number;
  matched: number;
  unmatched: number;
  cist: CistResponse[];
};
export type ContentRequest = {
  historyLogUUID: string;
  serverUrl: string;
};
export type GraphicResponse = {
  svgList: string[];
};
export type VoicesResponse = {
  nth?: number;
  voice: string[];
};
export type VoiceListResponse = {
  voices: VoicesResponse[];
};
export type UserListResponse = EntityListResponse & {
  users: UserResponse[];
};
export type GameGroupListResponse = EntityListResponse & {
  gameGroups: GameGroupResponse[];
};
export type GameGroupRequest = {
  displayName: string;
  enabledFlag?: boolean;
  demoFlag?: boolean;
};
export type GameListResponse = EntityListResponse & {
  games: GameResponse[];
};
export type GameList = {
  gameItype?: number;
  nth?: number;
  enabledFlag?: boolean;
};
export type GameListRequest = {
  games?: GameList[];
};
export type CouponListResponse = EntityListResponse & {
  coupons: CouponResponse[];
};
export type CompanyRequest = {
  model: string;
  companyName: string;
  adminName: string;
  email: string;
  pw: string;
  phone: string;
  code: string;
  enabledFlag: boolean;
  comment?: string;
  gameGroupUUID: string;
  limitCount?: number;
};
export type CouponRequest = {
  code?: string;
  enabledFlag?: boolean;
  adminName?: string;
  pw?: string;
  phone?: string;
  comment?: string;
  fbUID?: string;
};
export type CompanyListResponse = EntityListResponse & {
  companies: CompanyResponse[];
};
export type StatisticsDauResponse = {
  historyCTS: number;
  dau: number;
};
export type StatisticCompanyResponse = EntityResponse & {
  companyName: string;
  companyCNT: number;
  companyPCT: number;
};
export type StatisticsResponse = {
  company: StatisticCompanyResponse;
};
export type StatisticCistResponse = EntityResponse & {
  normalPCT: number;
  normalCNT: number;
  riskPCT: number;
  riskCNT: number;
  unknownPCT: number;
  unknownCNT: number;
};
export type StatisticGenderResponse = EntityResponse & {
  malePCT: number;
  maleCNT: number;
  femalePCT: number;
  femaleCNT: number;
};
export type StatisticAgeResponse = EntityResponse & {
  fortyPCT: number;
  fortyCNT: number;
  fiftyPCT: number;
  fiftyCNT: number;
  sixtyPCT: number;
  sixtyCNT: number;
  seventyPCT: number;
  seventyCNT: number;
  eightyPCT: number;
  eightyCNT: number;
};
export type StatisticsListResponse = EntityListResponse & {
  daus: StatisticsDauResponse[];
  statistics: StatisticsResponse[];
  total: number;
  cist: StatisticCistResponse;
  gender: StatisticGenderResponse;
  age: StatisticAgeResponse;
};
export type AnTCompany = {
  no?: string;
  name?: string;
  uuid?: string;
  codePrefix?: string;
};
export type AnTRole = {
  uuid?: string;
  name?: string;
};
export type AnTAdmin = {
  no?: number;
  uuid?: string;
  name?: string;
  email?: string;
  company?: AnTCompany;
  role?: AnTRole;
};
export type AnTAdminLoginResponse = AnTAdmin & {
  isSuperAdmin?: boolean;
};
export type AnTAverageByDomain = {
  total?: number;
  calculation: number;
  attention: number;
  memory: number;
  language: number;
  executive: number;
  visuoSpatial: number;
};
export type AnTDashboardAStatistics = {
  accumulatedRegistrationCount: number;
  firstAssessmentCompleteCount: number;
  reassessmentTargetCountList: number[];
  assessmentCompletionRateByRoundList: number[];
  averageAssessmentTimeByRoundList: number[];
  averageScoresByDomain: AnTAverageByDomain[];
};
export type AnTDashboardTStatisticsPlayRatio = {
  custom: AnTAverageByDomain;
  general: AnTAverageByDomain;
  total: AnTAverageByDomain;
};
export type AnTDashboardTStatistics = {
  averageIndividualPlayTime: number;
  dailyUserCount: number;
  averageCorrectRate: AnTAverageByDomain;
  playRatio: AnTDashboardTStatisticsPlayRatio;
};
export type AnTDashboardResponse = {
  alzguardA?: AnTDashboardAStatistics;
  alzguardT?: AnTDashboardTStatistics;
};
export type AnTDashboardFilter = {
  companyUUID?: string;
  startTimestamp?: number;
  endTimestamp?: number;
  tCategory?: string;
};
export type AnTCompaniesResponse = AnTCompany[];
export type AnTCompaniesCreateRequest = {
  name: string;
  codePrefix: string;
};
export type AnTCompaniesDeleteRequest = {
  companyUUIDList: string[];
};
export type AnTCompanyResponse = AnTCompany;
export type AnTCompanyUpdateRequest = AnTCompany;
export type AnTCompanyUpdateResponse = AnTCompany;
export type AnTUser = {
  no?: number;
  appUserUUID?: string;
  company?: AnTCompany;
  name?: string;
  phone?: string;
  reassessmentDays?: number;
  isBreakaway?: boolean;
};
export type AnTUserListResponse = {
  totalCount: number;
  list: AnTUser[];
};
export type AnTUsersDeleteRequest = {
  appUserUUIDList: string[];
};
export type AnTUserListFilter = {
  companyUUID?: string;
  name?: string;
  phone?: string;
  reassessmentDays?: number;
  isBreakaway?: boolean;
  isOnlyReassessmentTargetDisplayed?: boolean;
};
export type Pagination = {
  limit?: number;
  page?: number;
};
export type Sorting = {
  keys?: string[];
  dirs?: string[];
};
export type AnTUserMedicalResultInfo = {
  score: number;
  severity: string;
};
export type AnTUserMedicalResult = {
  medicalResultUUID: string;
  date: string;
  "sgds-k"?: AnTUserMedicalResultInfo;
  cist?: AnTUserMedicalResultInfo;
};
export type AnTUserAlzguardAGameResult = {
  domain: string;
  gameName: string;
  totalQuestions: number;
  correctQuestions: number;
  duration: number;
  isCorrectList: boolean[];
  reactionTimeList: number[];
};
export type AnTUserAlzguardAHistory = {
  round: number;
  date: string;
  startTM: string;
  endTM: string;
  results: AnTUserAlzguardAGameResult[];
};
export type AnTUserResponse = {
  userInfo: AnTUser & {
    gender?: string;
    birthDate?: string;
    age?: number;
    edu?: string;
    registrationDate?: string;
    sns?: string;
    email?: string;
  };
  medicalResultList?: AnTUserMedicalResult[];
  alzguardAHistories?: AnTUserAlzguardAHistory[];
};
export type AnTUserUpdateRequest = AnTUser & {
  gender?: string;
  birthDate?: string;
  edu?: string;
  companyUUID?: string;
};
export type AnTUserMedicalResultRequest = {
  date?: string;
  cistScore?: number;
  sgdsKScore?: number;
};
export type AnTTrainingResult = {
  name: string;
  startTimestamp: number;
  endTimestamp?: number;
  totalQuestions: number;
  correctQuestions?: number;
  level: number;
};
export type AnTUserTrainingResultsResponse = {
  totalCount: number;
  list: AnTTrainingResult[];
};
export type AnTCoupon = {
  no?: number;
  uuid?: string;
  company?: AnTCompany;
  name?: string;
  issuedCount?: number;
  registeredCount?: number;
  availableDays?: number;
  issuedAt?: number;
  expiredAt?: number;
  isActive?: boolean;
};
export type AnTCouponsResponse = {
  totalCount: number;
  list: AnTCoupon[];
};
export type AnTCouponsDeleteRequest = {
  couponUUIDList: string[];
};
export type AnTCouponsCreateRequest = {
  companyUUID: string;
  issuedCount: number;
  availableDays: number;
  reuseIntervalDays?: number;
};
export type AnTCouponResponse = AnTCoupon;
export type AnTCouponUpdateRequest = {
  availableDays?: number;
  isActive?: boolean;
};
export type AnTCouponUpdateResponse = AnTCoupon;
export type AnTRolesResponse = AnTRole[];
export type AnTAdminsResponse = {
  totalCount: number;
  list: AnTAdmin[];
};
export type AnTAdminsDeleteRequest = {
  adminUUIDList: string[];
};
export type AnTAdminsCreateRequest = {
  name: string;
  email: string;
  companyUUID: string;
  roleUUID: string;
};
export type AnTAdminResponse = AnTAdmin;
export type AnTAdminUpdateRequest = {
  name?: string;
  companyUUID?: string;
  roleUUID?: string;
};
export type AnTAdminUpdateResponse = AnTAdmin;
export type AnTPushNotificationsNotifyRequest = {
  companyUUID?: string;
  title: string;
  body: string;
};
export type AnTPushNotificationsSchedulerNotifyRequest = {
  executeTime: string;
};
export type AnTPushNotificationHistory = {
  no?: number;
  uuid?: string;
  company?: AnTCompany;
  sentTimestamp?: number;
  title?: string;
  body?: string;
};
export type AnTPushNotificationHistoriesResponse = {
  totalCount: number;
  list: AnTPushNotificationHistory[];
};
export type AnTPushNotificationHistoryResponse = AnTPushNotificationHistory;

/**
 * 로그인
 */
export function v1AdminLogin(
  loginRequest: LoginRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: PermissionResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/login",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: loginRequest,
    })
  );
}

/**
 * 로그아웃
 */
export function v1AdminLogout(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/logout", {
    ...opts,
    method: "POST",
  });
}

/**
 * 히스토리 리스트 조회
 */
export function v1AdminListHistory(
  {
    filter,
    sorting,
    pagination,
  }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: HistoryListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/histories${QS.query(
      QS.form({
        filter,
        sorting,
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * 히스토리 정보 조회
 */
export function v1AdminRetrieveHistory(
  historyUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: HistoryResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/histories/${historyUuid}`, {
    ...opts,
  });
}

/**
 * 히스토리 사용자 정보 수정
 */
export function v1AdminModifyHistory(
  historyUuid: string,
  userRequest: UserRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/histories/${historyUuid}`,
    oazapfts.json({
      ...opts,
      method: "PUT",
      body: userRequest,
    })
  );
}

/**
 * 히스토리 리스트 엑셀 다운로드
 */
export function v1AdminListHistoryExcel(
  {
    filter,
    sorting,
    pagination,
  }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchBlob<
    | {
        status: 200;
        data: Blob;
      }
    | {
        status: 418;
      }
  >(
    `/v1/admin/histories/excel${QS.query(
      QS.form({
        filter,
        sorting,
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * 신경인지검사 결과 엑셀 자료 업로드
 */
export function v1AdminHistoryExcelUpload(
  historyExcelUploadRequest: HistoryExcelUploadRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: HistoryExcelUploadResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/histories/excel/upload",
    oazapfts.multipart({
      ...opts,
      method: "POST",
      body: historyExcelUploadRequest,
    })
  );
}

/**
 * 시선추적 그래프 생성
 */
export function v1AdminContentEyeTracking(
  contentRequest: ContentRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GraphicResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/content/eyeTracking",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: contentRequest,
    })
  );
}

/**
 * 음성 컨텐츠 다운로드
 */
export function v1AdminContentVoices(
  contentRequest: ContentRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: VoiceListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/content/voices",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: contentRequest,
    })
  );
}

/**
 * 사용자 리스트 조회
 */
export function v1AdminListUser(
  {
    filter,
    sorting,
    pagination,
  }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: UserListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/users${QS.query(
      QS.form({
        filter,
        sorting,
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * 사용자 정보 조회
 */
export function v1AdminRetrieveUser(
  userUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: UserResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/users/${userUuid}`, {
    ...opts,
  });
}

/**
 * 사용자 정보 수정
 */
export function v1AdminModifyUser(
  userUuid: string,
  userRequest: UserRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: UserResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/users/${userUuid}`,
    oazapfts.json({
      ...opts,
      method: "PUT",
      body: userRequest,
    })
  );
}

/**
 * 사용자 정보 업데이트 및 삭제
 */
export function v1AdminMergeUser(
  userUuid: string,
  {
    filter,
  }: {
    filter?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/users/${userUuid}${QS.query(
      QS.form({
        filter,
      })
    )}`,
    {
      ...opts,
      method: "DELETE",
    }
  );
}

/**
 * 사용자별 게임 결과 조회
 */
export function v1AdminRetrieveUserHistory(
  userUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: HistoryLogListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/users/${userUuid}/history`, {
    ...opts,
  });
}

/**
 * 게임 그룹 조회
 */
export function v1AdminListGameGroup(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GameGroupListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/games", {
    ...opts,
  });
}

/**
 * 게임 그룹 등록
 */
export function v1AdminCreateGameGroup(
  gameGroupRequest: GameGroupRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GameGroupResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/games",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: gameGroupRequest,
    })
  );
}

/**
 * 그룹별 게임 리스트 조회
 */
export function v1AdminListGame(
  groupUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GameListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/games/${groupUuid}`, {
    ...opts,
  });
}

/**
 * 그룹별 게임 리스트 수정
 */
export function v1AdminModifyGame(
  groupUuid: string,
  gameListRequest: GameListRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GameListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/games/${groupUuid}`,
    oazapfts.json({
      ...opts,
      method: "PUT",
      body: gameListRequest,
    })
  );
}

/**
 * 게임 그룹 수정
 */
export function v1AdminModifyGameGroup(
  groupUuid: string,
  gameGroupRequest: GameGroupRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GameGroupResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/games/group/${groupUuid}`,
    oazapfts.json({
      ...opts,
      method: "PUT",
      body: gameGroupRequest,
    })
  );
}

/**
 * 기관 조회
 */
export function v1AdminListCompany(
  {
    filter,
    sorting,
    pagination,
  }: {
    filter?: any;
    sorting?: any;
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: CouponListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/companies${QS.query(
      QS.form({
        filter,
        sorting,
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * 기관(담당자) 등록
 */
export function v1AdminCreateCompany(
  companyRequest: CompanyRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/companies",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: companyRequest,
    })
  );
}

/**
 * 기관 상세 조회
 */
export function v1AdminRetrieveCompany(
  couponUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: CouponResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/companies/${couponUuid}`, {
    ...opts,
  });
}

/**
 * 기관 정보 수정
 */
export function v1AdminModifyCompany(
  couponUuid: string,
  couponRequest: CouponRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: CouponResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/companies/${couponUuid}`,
    oazapfts.json({
      ...opts,
      method: "PUT",
      body: couponRequest,
    })
  );
}

/**
 * 기관명 조회
 */
export function v1AdminListCompanyName(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: CompanyListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/companies/name", {
    ...opts,
  });
}

/**
 * 이메일 | 이용권 코드 중복 검증
 */
export function v1AdminRetrieveValidation(
  {
    filter,
  }: {
    filter?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/validation${QS.query(
      QS.form({
        filter,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * 알츠가드 사용량 통계
 */
export function v1AdminListStatistics(
  {
    filter,
  }: {
    filter?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: StatisticsListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/statistics${QS.query(
      QS.form({
        filter,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * 알츠가드D 통계 생성
 */
export function v1AdminStatisticsDailyCreate(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/statistics/daily", {
    ...opts,
    method: "POST",
  });
}

/**
 * A&T 관리자 로그인
 */
export function v1AdminAntLogin(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTAdminLoginResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/ant/login", {
    ...opts,
    method: "POST",
  });
}

/**
 * A&T 관리자 로그아웃
 */
export function v1AdminAntLogout(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/ant/logout", {
    ...opts,
    method: "POST",
  });
}

/**
 * A&T 대시보드(알츠가드A 데이터)
 */
export function v1AdminAntDashboardA(
  {
    filter,
  }: {
    filter?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTDashboardResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/dashboard/a${QS.query(
      QS.form({
        filter,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 대시보드(알츠가드T 데이터)
 */
export function v1AdminAntDashboardT(
  {
    filter,
  }: {
    filter?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTDashboardResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/dashboard/t${QS.query(
      QS.form({
        filter,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 대시보드 엑셀 다운로드(전체)
 */
export function v1AdminAntDashboardExcelDownload(
  anTDashboardFilter?: AnTDashboardFilter,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Blob;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/dashboard/excelDownload.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTDashboardFilter,
    })
  );
}

/**
 * A&T 대시보드 엑셀 다운로드(a 또는 t)
 */
export function v1AdminAntDashboardServiceTypeExcelDownload(
  serviceType: string,
  anTDashboardFilter?: AnTDashboardFilter,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Blob;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/dashboard/${serviceType}/excelDownload.do`,
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTDashboardFilter,
    })
  );
}

/**
 * A&T 기관 목록 조회
 */
export function v1AdminAntCompanies(
  {
    filter,
    pagination,
    sorting,
  }: {
    filter?: any;
    pagination?: any;
    sorting?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTCompaniesResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/companies${QS.query(
      QS.form({
        filter,
        pagination,
        sorting,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 기관 등록
 */
export function v1AdminAntCompaniesCreate(
  anTCompaniesCreateRequest: AnTCompaniesCreateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/companies",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTCompaniesCreateRequest,
    })
  );
}

/**
 * A&T 기관 다중 삭제
 */
export function v1AdminAntCompaniesDelete(
  anTCompaniesDeleteRequest: AnTCompaniesDeleteRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/companies",
    oazapfts.json({
      ...opts,
      method: "DELETE",
      body: anTCompaniesDeleteRequest,
    })
  );
}

/**
 * A&T 기관 상세 조회
 */
export function v1AdminAntCompany(
  companyUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTCompanyResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/companies/${companyUuid}`, {
    ...opts,
  });
}

/**
 * A&T 기관 수정
 */
export function v1AdminAntCompanyUpdate(
  companyUuid: string,
  anTCompanyUpdateRequest: AnTCompanyUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTCompanyUpdateResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/companies/${companyUuid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: anTCompanyUpdateRequest,
    })
  );
}

/**
 * A&T 사용자 목록 조회
 */
export function v1AdminAntUsers(
  {
    filter,
    pagination,
    sorting,
  }: {
    filter?: any;
    pagination?: any;
    sorting?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTUserListResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/users${QS.query(
      QS.form({
        filter,
        pagination,
        sorting,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 사용자 다중 삭제
 */
export function v1AdminAntUsersDelete(
  anTUsersDeleteRequest: AnTUsersDeleteRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/users",
    oazapfts.json({
      ...opts,
      method: "DELETE",
      body: anTUsersDeleteRequest,
    })
  );
}

/**
 * A&T 사용자 목록 excel 다운로드
 */
export function v1AdminAntUsersExcelDownload(
  body?: {
    filter?: AnTUserListFilter;
    pagination?: Pagination;
    sorting?: Sorting;
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Blob;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/users/excelDownload.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body,
    })
  );
}

/**
 * A&T 유저 조회
 */
export function v1AdminAntUser(
  appUserUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTUserResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/users/${appUserUuid}`, {
    ...opts,
  });
}

/**
 * A&T 유저 수정
 */
export function v1AdminAntUserUpdate(
  appUserUuid: string,
  anTUserUpdateRequest: AnTUserUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTUserResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/users/${appUserUuid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: anTUserUpdateRequest,
    })
  );
}

/**
 * A&T 사용자 데이터 엑셀 다운로드
 */
export function v1AdminAntUserExcelDownload(
  appUserUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Blob;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/users/${appUserUuid}/excelDownload.do`, {
    ...opts,
    method: "POST",
  });
}

/**
 * A&T 사용자 의료 검사 결과 생성
 */
export function v1AdminAntUserMedicalResultCreate(
  appUserUuid: string,
  anTUserMedicalResultRequest: AnTUserMedicalResultRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTUserMedicalResult;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/users/${appUserUuid}/medicalResults`,
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTUserMedicalResultRequest,
    })
  );
}

/**
 * A&T 사용자 의료 검사 결과 수정
 */
export function v1AdminAntUserMedicalResultUpdate(
  medicalResultUuid: string,
  appUserUuid: string,
  anTUserMedicalResultRequest: AnTUserMedicalResultRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTUserMedicalResult;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/users/${appUserUuid}/medicalResults/${medicalResultUuid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: anTUserMedicalResultRequest,
    })
  );
}

/**
 * A&T 사용자 알츠가드T 결과 목록 조회
 */
export function v1AdminAntUserTrainingResults(
  appUserUuid: string,
  {
    filter,
    pagination,
    sorting,
  }: {
    filter?: any;
    pagination?: any;
    sorting?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTUserTrainingResultsResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/users/${appUserUuid}/trainingResults${QS.query(
      QS.form({
        filter,
        pagination,
        sorting,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 사용자 알츠가드A 히스토리 엑셀 다운로드
 */
export function v1AdminAntUserHistoriesExcelDownload(
  appUserUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Blob;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/users/${appUserUuid}/histories/excelDownload.do`, {
    ...opts,
    method: "POST",
  });
}

/**
 * A&T 사용권 코드 목록 조회
 */
export function v1AdminAntCoupons(
  {
    filter,
    pagination,
    sorting,
  }: {
    filter?: any;
    pagination?: any;
    sorting?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTCouponsResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/coupons${QS.query(
      QS.form({
        filter,
        pagination,
        sorting,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 사용권 코드 다중 삭제
 */
export function v1AdminAntCouponsDelete(
  anTCouponsDeleteRequest: AnTCouponsDeleteRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/coupons",
    oazapfts.json({
      ...opts,
      method: "DELETE",
      body: anTCouponsDeleteRequest,
    })
  );
}

/**
 * A&T 사용권 코드 생성
 */
export function v1AdminAntCouponsCreate(
  anTCouponsCreateRequest: AnTCouponsCreateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/coupons",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTCouponsCreateRequest,
    })
  );
}

/**
 * A&T 사용권 코드 상세 조회
 */
export function v1AdminAntCoupon(
  couponUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTCouponResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/coupons/${couponUuid}`, {
    ...opts,
  });
}

/**
 * A&T 사용권 코드 수정
 */
export function v1AdminAntCouponUpdate(
  couponUuid: string,
  anTCouponUpdateRequest: AnTCouponUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTCouponUpdateResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/coupons/${couponUuid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: anTCouponUpdateRequest,
    })
  );
}

/**
 * A&T 어드민 권한 목록 조회
 */
export function v1AdminAntRoles(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTRolesResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/ant/roles", {
    ...opts,
  });
}

/**
 * A&T 관리자 목록 조회
 */
export function v1AdminAntAdmins(
  {
    filter,
    pagination,
    sorting,
  }: {
    filter?: any;
    pagination?: any;
    sorting?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTAdminsResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/admins${QS.query(
      QS.form({
        filter,
        pagination,
        sorting,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T 관리자 다중 삭제
 */
export function v1AdminAntAdminsDelete(
  anTAdminsDeleteRequest: AnTAdminsDeleteRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/admins",
    oazapfts.json({
      ...opts,
      method: "DELETE",
      body: anTAdminsDeleteRequest,
    })
  );
}

/**
 * A&T 관리자 생성
 */
export function v1AdminAntAdminsCreate(
  anTAdminsCreateRequest: AnTAdminsCreateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/admins",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTAdminsCreateRequest,
    })
  );
}

/**
 * A&T 관리자 상세 조회
 */
export function v1AdminAntAdmin(
  adminUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTAdminResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/admins/${adminUuid}`, {
    ...opts,
  });
}

/**
 * A&T 관리자 수정
 */
export function v1AdminAntAdminUpdate(
  adminUuid: string,
  anTAdminUpdateRequest?: AnTAdminUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTAdminUpdateResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/admins/${adminUuid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: anTAdminUpdateRequest,
    })
  );
}

/**
 * A&T 푸시 알림 발송
 */
export function v1AdminAntPushNotificationsNotify(
  anTPushNotificationsNotifyRequest: AnTPushNotificationsNotifyRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/pushNotifications/notify.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTPushNotificationsNotifyRequest,
    })
  );
}

/**
 * A&T push 알림 전송(스케줄러)
 */
export function v1AdminAntPushNotificationsSchedulerNotify(
  anTPushNotificationsSchedulerNotifyRequest: AnTPushNotificationsSchedulerNotifyRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/ant/pushNotifications/scheduler/notify.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: anTPushNotificationsSchedulerNotifyRequest,
    })
  );
}

/**
 * A&T 푸시 알림 목록 조회
 */
export function v1AdminAntPushNotificationHistories(
  {
    filter,
    pagination,
    sorting,
  }: {
    filter?: any;
    pagination?: any;
    sorting?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTPushNotificationHistoriesResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/ant/pushNotificationHistories${QS.query(
      QS.form({
        filter,
        pagination,
        sorting,
      })
    )}`,
    {
      ...opts,
    }
  );
}

/**
 * A&T push 알림 히스토리 상세 조회
 */
export function v1AdminAntPushNotificationHistory(
  pushNotificationHistoryUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: AnTPushNotificationHistoryResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/ant/pushNotificationHistories/${pushNotificationHistoryUuid}`, {
    ...opts,
  });
}
