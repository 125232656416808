




import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class App extends Vue {
  created(): void {
    const locale = this.$store.getters.setting.locale;
    if (locale === "en") {
      this.$vuetify.lang.current = locale;
    }
  }
}
