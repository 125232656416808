/* eslint-disable no-console */

import { unregister } from "register-service-worker";

if ("serviceWorker" in navigator) {
  if (process.env.NODE_ENV === "production") {
    unregister();
  }
}

let refreshing = false;
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    console.log("service worker controller changed, refreshing");
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
  });
}
