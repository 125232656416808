import {
  adminInterface,
  RootState,
  settingInterface,
  state,
} from "@/store/state";
import { MutationTree } from "vuex";

enum MutationTypes {
  CHANGE_LOADING_STATE = "CHANGE_LOADING_STATE",
  ADMIN_SET = "ADMIN_SET",
  SETTING_SET = "SETTING_SET",
  SET_BANNER_STATE = "SET_BANNER_STATE",
  SET_META_TITLE = "SET_META_TITLE",
}

const mutations: MutationTree<typeof state> = {
  [MutationTypes.ADMIN_SET](state: RootState, admin: adminInterface): void {
    state.admin = admin;
  },
  [MutationTypes.CHANGE_LOADING_STATE](
    state: RootState,
    boolean: boolean
  ): void {
    state.setting.loading = boolean;
  },
  [MutationTypes.SETTING_SET](state: RootState, setting: settingInterface) {
    state.setting = { ...state.setting, ...setting };
  },
  [MutationTypes.SET_BANNER_STATE](state: RootState, bannerState: boolean) {
    state.bannerState = bannerState;
  },
  [MutationTypes.SET_META_TITLE](state: RootState, title: string) {
    state.metaTag.title = title;
  },
};

type Mutations = typeof mutations;

export { MutationTypes, mutations, Mutations };
