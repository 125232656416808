import vuetifyEN from "vuetify/src/locale/en";

import {
  $CommonType,
  gameGroupCommonType,
  institutionCommonType,
  LocaleType,
} from ".";

const $Common: $CommonType = {
  alztalk: "Alztalk",
  all: "All",
  cancel: "Cancel",
  confirmDelete: "Are you sure you want to delete this?",
  delete: "Delete",
  haii: "ALZTALK",
  modeDevops: "Devops View",
  none: "None",
  ok: "OK",
  or: "or",
  subTitle: "",
  title: "",
  today: "Today",
  unknown: "Unknown",
  weekdaysLong: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday",
  weekdaysShort: "Sun Mon Tue Wed Thu Fri Sat",
  yesterday: "Yesterday",
  startDt: "StartDate",
  endDt: "EndDate",
  testDate: "Test Date",
  validateDate:
    "The start date is later than the end date. Please change to a date before the end date",
  userName: "name",
  index: "#",
  gender: "gender",
  age: "age",
  male: "male",
  female: "female",
  language: "language",
  testerFlag: "Test user status",
  checkTesterFlag: "Only test users can watch it",
  total: "Total",
  userKey: "User Key",
  birthDT: "Birthday",
  phone: "phone",
  startTM: "Test start time.",
  endTM: "Test end time.",
  completedFlag: "Complete game status",
  noData: "The data is not available.",
  onEdit: "Save",
  editSuccess: "The modification has been completed.",
  registerSuccess: "The register has been completed.",
  phoneFormatError: "The phone format is not correct. Please check",
  registration: "registration",
  activity: "Activity",
  person: "person",
};

const institutionCommonType: institutionCommonType = {
  institutionName: "Name of the institution",
  couponCode: "Voucher code",
  responsibility: "responsibility",
  periodOfUse: "The period of use",
  codeActivity: "Code Active",
  model: "model",
  email: "email",
  password: "password",
  emailExisted: "This Email already existed",
  registerInstitution: "Institutional registration",
  gameGroup: "Game Group",
};

const gameGroupCommonType: gameGroupCommonType = {
  gameGroup: "Game Group",
  deactivatedGames: "Deactivated Games",
  activatedGames: "Activated Games",
  gameName: "Game Name",
  AllDeactivated: "Deactivation of all",
  AllActivated: "Activation of all",
};

export const en: LocaleType = {
  ...vuetifyEN,

  ReportList: {
    ...$Common,
    ...institutionCommonType,
    title: "AZTalk Results Analysis",
    historyUUID: "HistoryUUID",
    gameGroupName: "GameGroupName",
    completedFlag: "CompletedFlag",
    result: "Result",
    downloadReport: "Download Results Report",

    editInfo: "Edit Information",
  },
  AppBar: $Common,

  SignIn: {
    ...$Common,
    subTitle: "Hello, this is Alztalk Admin.",
    loginMessage: "Sign in with Google",
    googleLoginMessage: "Sign in with Google",
    emailLoginMessage: "Sign in with E-mail",
  },
  ShowImage: {
    ...$Common,

    btnName: "Show Image",
    noImage: "Image not found",
  },
  ResultSummary: {
    ...$Common,

    totalNumOfQuestion: "Total number of questions",
    correctAnswerNum: "The number of correct answers",
    playTime: "PlayTime",
    totalResponseTime: "totalResponseTime",
    averageResponseTime: "average of ResponseTime",
    question: "question",
    answer: "answer",
    responseTime: "responseTime",
  },
  BasicInfo: {
    ...$Common,
    ...institutionCommonType,
    registerDate: "Join Date",
  },
  GameDetailInfo: {
    ...$Common,

    detailData: "detailed data",
  },
  NavigationMenuList: {
    ...$Common,
    report: "ReportList",
    alzguardReport: "Alzguard Report",
    userManagement: "User Management",
    gameGroupManagement: "Game Group Management",
    serviceManagement: "Service Management",
    InstitutionList: "Institution List",
  },
  Report: {
    ...$Common,
    noGameData: "The Game data is not available.",
    basicInfo: "basicInfo",
    hospitalExaminationResults: "hospital",
    resultReport: "Result report",
  },
  PlayAudio: {
    ...$Common,

    playAudio: "Audio playback ",
    noData: "The data is not available.",
  },
  Banner: {
    ...$Common,

    update: "There is a new update history. please refresh",
    refresh: "refresh",
  },
  ExcelDataUpload: {
    ...$Common,
    title: "Upload hospital data",
    testDate: "TestDay",
    inputDate: "Please enter test date",
    inputFile: "Please enter the file to be uploaded",
    inputSheet: "Please enter sheet name",
    missMatchedResult: "Unmatched list",
    sheetName: "Sheet name",
    sheetInputHint:
      "Please enter the name of the excel sheet containing the CIST results(Case sensitive required) ",
  },
  NavigationSetting: {
    ...$Common,
  },
  Pagination: {
    ...$Common,
  },
  UserList: {
    ...$Common,
    ...institutionCommonType,
    title: "User List",
  },
  User: {
    ...$Common,
    ...institutionCommonType,
    basicInfo: "Basic Information",
    detailTests: "Detailed view by test",
    registerDate: "Join Date",
  },
  DialogEditHistoryUserInfo: {
    ...$Common,
    editTitle: "Edit",
  },
  MergeDialog: {
    ...$Common,
    title: "The same user exists. Do you want to continue editing?",
    edu: "education",
    eduYear: "The number of years of education.",
    kMMSETotal: "K-MMSE-2(B) Total Score",
    kMMSEPercent: "K-MMSE-2(B) Percentile",
    cistTotal: "CIST Total Score",
    cistResult: "CIST_result(normal:0, Suspicion of cognitive decline.:1)",
    name: "",
    diagnosis: "Hospital diagnosis result Normal: 0, MCI: 1, Dementia: 2",
  },
  CistResult: {
    ...$Common,
    edu: "education",
    eduYear: "The number of years of education.",
    kMMSETotal: "K-MMSE-2(B) Total Score",
    kMMSEPercent: "K-MMSE-2(B) Percentile",
    cistTotal: "CIST Total Score",
    cistResult: "CIST_result(normal:0, Suspicion of cognitive decline.:1)",
    name: "",
    diagnosis: "Hospital diagnosis result (Normal: 0, MCI: 1, Dementia: 2)",
  },
  GameGroupList: {
    ...$Common,
    ...gameGroupCommonType,
    demoGameGroup: "Demo game group",
  },
  Games: {
    ...$Common,
    ...gameGroupCommonType,
  },
  InstitutionList: {
    ...$Common,
    ...institutionCommonType,
    title: "Institution List",
  },
  RegisterInstitution: {
    ...$Common,
    ...institutionCommonType,
    ...gameGroupCommonType,
    generateRandom: "Randomly generated",
    checkOverlap: "",
    reset: "reset",
  },
  Institution: {
    ...$Common,
    ...institutionCommonType,
    title: "View detailed list of institutions",
    editPassword: "Password(not required)",
  },
  ConfirmDialog: {
    ...$Common,
    ...institutionCommonType,
    ...gameGroupCommonType,
  },
  CreateGameGroup: {
    ...$Common,
    ...gameGroupCommonType,
    createGameGroup: "Create game group",
    inputGameGroupName: "Enter the game group name",
    demoOrNot: "whether demo game group",
    gameGroupNameInputHint:
      "When set to DEMO game group, it is automatically set to DEMO_OO.",
  },
  DashboardBasicInfo: {
    ...$Common,
    basicInfo: "Basic Information",
    totalParticipantsNum: "Number of participants in Alzguard",
    participantsByInstitution: "Number of participants by institution",
    genderRate: "Gender ratio",
    ageRate: "Age ratio",
    normal: "Normal",
    risk: "Risk",
    unknown: "Unknown",
    ageGroup: "'s",
    more: "more",
  },
  ActiveInfo: {
    ...$Common,
    activeInfo: "Basic Information",
    dau: "Daily active users",
    revisitRate: "Overall Revisit Percentage",
    exDayRateUpAndDown: "Increase/decrease rate based on the yesterday",
    exMonthRateUpAndDown: "Increase/decrease rate based on the previous month",
    useAveragePerPerson: "Average hours of use per person",
  },
  Dashboard: {
    ...$Common,
  },
  ExcelDownloadBtn: {
    ...$Common,
    title: "Excel Download",
    ok: "Download",
    userFlag: "Whether personal information is included",
  },
};
