// 현재 브라우저에서 서비스 워커와 푸시 메시지를 지원하는지 확인
import { getMessaging, onMessage } from "firebase/messaging";
import store from "@/store";
import { MutationTypes } from "@/store/mutations";
import firebase from "@/plugins/firebase/firebase";

export const cloudMessageInit = () => {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    const messaging = getMessaging(firebase);

    // 알림 수신을 위한 사용자 권한 요청
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        confirm("알림을 허용해주세요");
      }
    });

    // Handle received push notification at foreground
    onMessage(messaging, () => {
      store.commit(MutationTypes.SET_BANNER_STATE, true);
    });
  }
};
