import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import store from "@/store";
import { MutationTypes } from "@/store/mutations";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */ "@/layouts/default/index.vue"
      ),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "alzguard/reports",
        name: "ReportList",
        component: () =>
          import(
            /* webpackChunkName: "views-report" */
            "@/views/alzguard/reports/ReportList.vue"
          ),
      },
      {
        path: "alzguard/reports/:UUID",
        name: "Report",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "views-report" */
            "@/views/alzguard/reports/Report.vue"
          ),
      },

      {
        path: "users",
        name: "UserList",
        component: () =>
          import(
            /* webpackChunkName: "views-users" */
            "@/views/users/UserList.vue"
          ),
      },
      {
        path: "users/:UserUUID",
        name: "User",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "views-users" */
            "@/views/users/User.vue"
          ),
      },
      {
        path: "/users/:UserUUID/alzguard-result",
        name: "AlzguardResult",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "views-users" */
            "@/views/users/UserAlzguardResult.vue"
          ),
      },

      {
        path: "/game-group",
        name: "GameGroupList",
        component: () =>
          import(
            /* webpackChunkName: "views-gameGroup" */
            "@/views/alzguard/gameGroup/GameGroupList.vue"
          ),
      },
      {
        path: "/game-group/:UUID/:DEMO/:gameGroupName",
        name: "Games",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "views-gameGroup" */
            "@/views/alzguard/gameGroup/Games.vue"
          ),
      },

      {
        path: "institutions",
        name: "InstitutionList",
        component: () =>
          import(
            /* webpackChunkName: "views-institutions" */
            "@/views/institutions/InstitutionList.vue"
          ),
      },
      {
        path: "institutions/:UUID",
        name: "Institution",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "views-institutions" */
            "@/views/institutions/Institution.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  store.commit(MutationTypes.SET_META_TITLE, to.name);
});

export default router;
