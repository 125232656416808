
























































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ApexChart from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { StatisticsListResponse } from "@/generated/adminapi";

export interface ApexChartInterface {
  series: any;
  options: ApexOptions;
}

@Component({
  components: {
    ApexChart,
  },
})
export default class DashboardBasicInfo extends Vue {
  mountFlag = false;

  @Prop() statisticsListResponse: StatisticsListResponse;

  get isKo(): boolean {
    return this.$store.getters.setting.locale == "ko";
  }

  get chartHeight(): number {
    return window.innerHeight * 0.25;
  }

  get totalParticipantsNum(): ApexChartInterface {
    return {
      series: [
        this.checkUndefined(this.statisticsListResponse.cist?.normalCNT),
        this.checkUndefined(this.statisticsListResponse.cist?.riskCNT),
        this.checkUndefined(this.statisticsListResponse.cist?.unknownCNT),
      ],

      options: {
        legend: {
          position: "bottom",
        },

        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
          },
          textAnchor: "start",
        },
        labels: [
          this.$_haiiText("normal"),
          this.$_haiiText("risk"),
          this.$_haiiText("unknown"),
        ],
      },
    };
  }

  // get participantsByInstitutionSeries(){
  //   const companyList = this.statisticsListResponse.statistics.map(value => value.company.companyCNT)
  // }
  get participantsByInstitution(): ApexChartInterface {
    return {
      series: this.checkEmptyList(
        this.statisticsListResponse.statistics?.map(
          (value) => value?.company.companyCNT
        )
      ) as number[],
      options: {
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          textAnchor: "start",
        },
        labels: this.checkEmptyList(
          this.statisticsListResponse.statistics?.map(
            (value) => value?.company.companyName
          )
        ) as string[],
      },
    };
  }

  get gender(): ApexChartInterface {
    return {
      series: [
        this.checkUndefined(this.statisticsListResponse.gender?.maleCNT),
        this.checkUndefined(this.statisticsListResponse.gender?.femaleCNT),
      ],
      options: {
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          textAnchor: "start",
        },
        labels: [this.$_haiiText("male"), this.$_haiiText("female")],
      },
    };
  }

  get age(): ApexChartInterface {
    return {
      series: [
        {
          data: [
            {
              x: "40" + this.$_haiiText("ageGroup"),
              y: this.checkUndefined(this.statisticsListResponse.age?.fortyCNT),
            },
            {
              x: "50" + this.$_haiiText("ageGroup"),
              y: this.checkUndefined(this.statisticsListResponse.age?.fiftyCNT),
            },
            {
              x: "60" + this.$_haiiText("ageGroup"),
              y: this.checkUndefined(this.statisticsListResponse.age?.sixtyCNT),
            },
            {
              x: "70" + this.$_haiiText("ageGroup"),
              y: this.checkUndefined(
                this.statisticsListResponse.age?.seventyCNT
              ),
            },
            {
              x: "80" + this.$_haiiText("ageGroup") + this.$_haiiText("more"),
              y: this.checkUndefined(
                this.statisticsListResponse.age?.eightyCNT
              ),
            },
          ],
        },
      ],
      options: {
        dataLabels: {
          formatter(
            val: string | number | number[],
            opts?: any
          ): string | number {
            return val + (DashboardBasicInfo.prototype.isKo ? "명" : "");
          },
        },
        xaxis: {
          tickAmount: 5,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
      },
    };
  }

  mounted(): void {
    this.mountFlag = true;
  }

  checkUndefined(value: string | undefined | number): string | number {
    if (!value) return 0;
    return value;
  }

  checkEmptyList(value: string[] | number[]): string[] | number[] {
    if (!value) return [];
    return value;
  }
}
