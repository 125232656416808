export interface adminInterface {
  uid?: string;
  name?: string;
  email?: string;
  photoURL?: string;
}

export interface settingInterface {
  loading: boolean;
  locale: string;
  checkUpdateAlarm: boolean;
}

interface RootState {
  setting: settingInterface;
  admin: adminInterface;
  bannerState: boolean;
  metaTag: metaInterface;
}

export interface metaInterface {
  title: string;
}

const state: RootState = {
  setting: {
    loading: false,
    locale: "ko",
    checkUpdateAlarm: false,
  },
  admin: {
    uid: "",
    name: "",
    email: "",
    photoURL: "",
  },
  metaTag: {
    title: "",
  },
  bannerState: false,
};

export { state, RootState };
