export const devFirebaseConfig = {
  apiKey: "AIzaSyA_buA-sRAROHiRNbSu8zbI2h-DtGi9dBI",
  authDomain: "azt-admin.haiidev.co.kr",
  databaseURL: "https://haii-d-prj-azt-default-rtdb.firebaseio.com",
  projectId: "haii-d-prj-azt",
  storageBucket: "haii-d-prj-azt.appspot.com",
  messagingSenderId: "1024816742292",
  appId: "1:1024816742292:web:0ab8ea274fba92802c5981",
  measurementId: "G-3DQXX28D9F",
};

export const prodFirebaseConfig = {
  apiKey: "AIzaSyB09UA1TmCa7D4v2SPeSDHJ-qrPhCGiUe8",
  authDomain: "azt-admin.haii.io",
  projectId: "haii-p-prj-azt",
  storageBucket: "haii-p-prj-azt.appspot.com",
  messagingSenderId: "183198150820",
  appId: "1:183198150820:web:c2266baaad12efbafeb631",
};
