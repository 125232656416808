

















































import { Component, Prop, Ref, VModel, Vue } from "vue-property-decorator";

@Component
export default class DatePicker extends Vue {
  @Prop({ type: Boolean, default: false }) readonly clearable?: boolean;
  @Prop({ type: String, default: "" }) readonly label?: string;
  @Prop({ type: Boolean, default: false }) readonly range?: boolean;

  @VModel()
  date!: string | string[];

  @Ref("textField") readonly textField!: Vue;

  calendar = false;

  today = new Date();
  tmpDate = new Date();

  created(): void {
    this.today = new Date();
    this.tmpDate = new Date();
    this.tmpDate.setHours(0, 0, 0, 0);
  }

  get localeDate(): string {
    if (typeof this.date === "string") {
      const d = new Date(this.date);
      if (isNaN(d.getTime())) {
        return "";
      }
      return this.$_haii.datetime.toDateString(d);
    }
    if (Array.isArray(this.date)) {
      if (!this.date[0]) {
        return "";
      }
      return this.date.join(" ~ ");
    }
    return "";
  }

  allowedDates(calendarDate: string): boolean {
    const [year, month, day] = calendarDate
      .split("-")
      .map((str) => parseInt(str, 10));
    this.tmpDate.setFullYear(year, month - 1, day);

    return this.tmpDate.getTime() < this.today.getTime();
  }

  dayFormat(calendarDate: string): string {
    return calendarDate.split("-")[2].replace(/^0/, "");
  }

  onChange(value: string): void {
    this.calendar = false;
    this.$emit("change", value);
  }

  onClear(): void {
    this.date = "";
    if (this.textField.$options && this.textField.$options.methods) {
      this.textField.$options.methods.clearableCallback.call(this.textField);
    }

    this.calendar = false;
    this.$emit("clear");
  }
}
