import Vue from "vue";
import Vuetify from "vuetify";
import locale from "@/plugins/locale";

// toast message 를 편한게 사용하기 위한 라이브러리
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.use(Vuetify);

const checkUserLang = () => {
  const userLang = navigator.language;
  if (userLang === "ko-KR" || "ko") return "ko";
  else return "en";
};

export default new Vuetify({
  lang: {
    locales: {
      en: locale.en,
      ko: locale.ko,
    },
    current: checkUserLang(),
  },
});
