import Vue from "vue";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  devFirebaseConfig,
  prodFirebaseConfig,
} from "../../../firebase.config";

const firebase = initializeApp(
  process.env.VUE_APP_ENV === "production"
    ? prodFirebaseConfig
    : devFirebaseConfig
);

export const analytics = getAnalytics();

Vue.prototype.$firebase = firebase;
export default firebase;
