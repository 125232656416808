


















import Vue from "vue";
import { Component } from "vue-property-decorator";

import DashboardBasicInfo from "@/components/defaultLayout/dashboard/DashboardBasicInfo.vue";
import ActiveInfo from "@/components/defaultLayout/dashboard/ActiveInfo.vue";
import { StatisticsListResponse } from "@/generated/adminapi";

@Component({
  components: {
    ActiveInfo,
    DashboardBasicInfo,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class Dashboard extends Vue {
  title = this.$_haiiName();

  statisticsListResponse = {} as StatisticsListResponse;
  get dateRange(): [string, string] {
    return [
      this.$_haii.datetime.calculatePreviousDate(7),
      this.$_haii.datetime.toYYMMDD(new Date()),
    ];
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData(date?: [string, string]): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminListStatistics(
        date ?? this.dateRange
      );

      this.handleFetchData(data);
    } catch (error) {
      this.$_errorMessage(this.$_haiiText("noData"));
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: StatisticsListResponse | string): void {
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(data);
      return;
    }

    this.$_haii.analytics.readEvent(this.$_haiiName() + "_handleFetchData");

    this.statisticsListResponse = data;
  }
}
