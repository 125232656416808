import Vue, { VueConstructor } from "vue";
import UtilClass from "@/plugins/haii/util";
import DateTimeClass from "@/plugins/haii/datetime";
import ApiHelperClass, { serverType } from "@/plugins/haii/api-helper";
import AuthClass from "@/plugins/haii/auth";
import firebase from "firebase/app";
import * as adminAPI from "@/generated/adminapi/index";
import { ToastOptions, ToastType } from "vue-toasted";
import { MutationTypes } from "@/store/mutations";
import firebaseAnalytics from "@/plugins/firebase/analytics";

// 새로 class 추가시 plugin/haii/index.ts 의 v.prototype.$_haii 에도 추가해줘야함
export interface haiiInterface {
  util: typeof UtilClass;
  datetime: typeof DateTimeClass;
  auth: typeof AuthClass;
  analytics: typeof firebaseAnalytics;
}

declare module "vue/types/vue" {
  interface Vue {
    $firebase: typeof firebase;
    $_toastMessage: (
      icon: ToastType,
      message: string,
      timer?: number,
      option?: ToastOptions
    ) => void;
    $_errorMessage: (
      message: string,
      timer?: number,
      option?: ToastOptions
    ) => void;
    $_haii: haiiInterface;
    $_apiHelper: () => ApiHelperClass;
    $_haiiName: () => string;
    $_haiiDictionary: (
      index: string,
      key: string,
      ...params: Array<string | number>
    ) => string;
    $_haiiText: (key: string, ...params: Array<string | number>) => string;
    $_haiiBGColor: (color: string, level?: number) => string;
    $_changeLoadingState: (state: boolean) => void;
  }
}
interface addToastOptionsInterface extends ToastOptions {
  keepOnHover?: boolean;
}

export default {
  install: (v: VueConstructor): void => {
    v.prototype.$_haii = {
      util: UtilClass,
      datetime: DateTimeClass,
      auth: AuthClass,
      analytics: firebaseAnalytics,
    };

    v.prototype.$_haii.auth.init();

    // 함수를 전역적으로 사용하기 위해
    v.mixin({
      methods: {
        $_toastMessage(
          icon: ToastType,
          message: string,
          timer?: number,
          option?: addToastOptionsInterface
        ) {
          // 20글자 마다 줄바꿈
          message = (message + "").replace(/(.{20})/g, "$1<br/>");
          switch (icon) {
            case "success": {
              this.$toasted.success(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-check-circle-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                    }
              );

              break;
            }
            case "error": {
              this.$toasted.error(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-alert-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                    }
              );
              break;
            }
            case "info": {
              this.$toasted.info(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-information-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                    }
              );
              break;
            }
            default: {
              this.$toasted.show("에러");

              break;
            }
          }
        },
        $_errorMessage(
          message: string,
          timer?: number,
          option?: addToastOptionsInterface
        ) {
          try {
            message = (message + "").replace(/(.{20})/g, "$1<br/>");
            this.$toasted.error(
              message,
              option
                ? option
                : {
                    icon: "mdi-alert-outline",
                    duration: timer ? timer : 4000,
                    keepOnHover: true,
                  }
            );
          } catch (e) {
            console.warn(e);
          }
        },
        $_apiHelper() {
          return new ApiHelperClass("admin");
        },
        $_haiiName(): string {
          const vConstructor = this.constructor as typeof Vue.prototype;
          return vConstructor.options.name;
        },

        $_haiiDictionary(
          index: string,
          key: string,
          ...params: Array<string | number>
        ): string {
          return key
            ? this.$vuetify.lang.t(`$vuetify.${index}.${key}`, ...params)
            : key;
        },

        $_haiiText(key: string, ...params: Array<string | number>): string {
          return this.$_haiiDictionary(this.$_haiiName(), key, ...params);
        },
        $_changeLoadingState(state: boolean) {
          return this.$store.commit(MutationTypes.CHANGE_LOADING_STATE, state);
        },
      },
    });
  },
};
