import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import haii from "@/plugins/haii";
import Meta from "vue-meta";
import "@/plugins/firebase";
import "./plugins/toasted";
import "@/assets/default.css";

Vue.use(haii);
Vue.use(Meta, {
  keyName: "metaInfo", //component 에서 meta를 지정해줄 때 keyName에 지정된 이름으로 부를 수 있다.
  attribute: "data-vue-meta", //실제적으로 meta 태그에 vue-meta를 관리한다는 attribute를 구분 값으로 심어준다.
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid", // 이 구분 값으로 meta 태그를 새로 만들어 쌓는게 아니라 name에 따라 meta 태그를 변화시킴
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
