import store from "@/store";
import moment from "moment";

interface Clock {
  hours: number;
  minutes: number;
  seconds: number;
}

// 출력할 포맷이 다양하기에 한군데 모아서 관리
class DateTimeClass {
  static readonly MMDD: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
  };

  static readonly HHMM: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };

  static readonly HHMMSS: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  static timestampToDate(
    timestamp?: number | Date | string | null
  ): Date | null {
    if (timestamp === null || timestamp === undefined) {
      return null;
    } else if (timestamp instanceof Date) {
      return timestamp;
    }

    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  }

  static toString(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleString(store.getters.setting.locale)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toTimestamp(
    date?: string | Date | null,
    alt?: string
  ): number | string {
    return date ? new Date(date).getTime() : alt === undefined ? "n/a" : alt;
  }

  static toDateString(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);

    return date
      ? date.toLocaleDateString(store.getters.setting.locale)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toYYMMDD(
    timestamp?: number | Date | string | null,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    moment.locale(store.getters.setting.locale);

    return date
      ? moment(date).format("YYYY-MM-DD")
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toMMDD(
    timestamp?: number | Date | string | null,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleDateString(
          store.getters.setting.locale,
          DateTimeClass.MMDD
        )
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toTimeString(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(store.getters.setting.locale)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toHHMM(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(
          store.getters.setting.locale,
          DateTimeClass.HHMM
        )
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toHHMMSS(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(
          store.getters.setting.locale,
          DateTimeClass.HHMMSS
        )
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static toCalendarDate(
    timestamp?: number | Date | string | null,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    if (date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return [
        year.toString(),
        month.toString().padStart(2, "0"),
        day.toString().padStart(2, "0"),
      ].join("-");
    }
    return alt === undefined ? "n/a" : alt;
  }

  static toCalendarTime(timestamp?: number | Date, alt?: string): string {
    return DateTimeClass.toHHMM(timestamp, alt);
  }

  static toCalendar(timestamp?: number | Date, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? `${DateTimeClass.toCalendarDate(
          timestamp
        )} ${DateTimeClass.toCalendarTime(timestamp)}`
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static durationToHHMMSS(duration: number | null): string {
    const clocks = [0, 0, 0]; // hours, minutes, seconds
    if (duration) {
      clocks[2] = duration / 1000;
      clocks[0] = Math.floor(clocks[2] / 3600);
      clocks[2] %= 3600;
      clocks[1] = Math.floor(clocks[2] / 60);
      clocks[2] %= 60;
    }
    return clocks.map((clock) => clock.toString(10).padStart(2, "0")).join(":");
  }

  static hhmmssToClock(hhmmss?: string | null): Clock | null {
    if (hhmmss === null || hhmmss === undefined || hhmmss === "") {
      return null;
    }

    const clock: Clock = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    const tokens = hhmmss.split(":");
    switch (tokens.length) {
      case 3:
        clock.seconds = parseInt(tokens[2], 10);
      // fallthrough
      case 2:
        clock.minutes = parseInt(tokens[1], 10);
      // fallthrough
      case 1:
        clock.hours = parseInt(tokens[0], 10);
        if (Object.is(clock.hours, -0)) {
          clock.minutes = -clock.minutes;
        }
        break;
      default:
        throw Error("Invalid time");
    }

    return clock;
  }

  static hhmmssToDate(hhmmss?: string | null): Date | null {
    const clock = DateTimeClass.hhmmssToClock(hhmmss);
    if (!clock) {
      return null;
    }

    const date = new Date();
    date.setSeconds(clock.seconds);
    date.setMinutes(clock.minutes);
    date.setHours(clock.hours);

    return date;
  }

  static hhmmssToTimeString(hhmmss?: string | null, alt?: string): string {
    const date = DateTimeClass.hhmmssToDate(hhmmss);
    return date
      ? DateTimeClass.toTimeString(date)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  static timeGapToMinutes(timeGap: string): number {
    const clock = DateTimeClass.hhmmssToClock(timeGap);
    return clock ? clock.hours * 60 + clock.minutes : 0;
  }

  static timeGapToSeconds(timeGap: string): number {
    const clock = DateTimeClass.hhmmssToClock(timeGap);
    return clock
      ? clock.hours * 60 * 60 + clock.minutes * 60 + clock.seconds
      : 0;
  }

  static timeGapToMinutesString(timeGap: string): string {
    const minutes = DateTimeClass.timeGapToMinutes(timeGap);
    return minutes > 0 ? `+${minutes}` : minutes.toString();
  }

  static timeGapToTimeString(hhmmss: string, timeGap: string): string {
    const date = DateTimeClass.hhmmssToDate(hhmmss);
    if (!date) {
      return "-";
    }
    date.setMinutes(
      date.getMinutes() + DateTimeClass.timeGapToMinutes(timeGap)
    );
    return DateTimeClass.toTimeString(date);
  }

  static timeToHHMMSS(startTime: string, endTime: string): string {
    const firstSeconds = this.timeGapToSeconds(startTime);
    const secondSeconds = this.timeGapToSeconds(endTime);

    const seconds = firstSeconds - secondSeconds;
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor(seconds % 60);

    const isKo = store.getters.setting.locale == "ko";

    const clockHour = hour != 0 ? hour + (isKo ? "시간" : "hour") : "";
    const clockMin = min != 0 ? min + (isKo ? "분" : "minutes") : "";
    const clockSec = sec != 0 ? sec + (isKo ? "초" : "seconds") : "0초";

    return clockHour + clockMin + clockSec;
  }

  static secondsToHHMMSS(seconds: number): string {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = Math.round(seconds % 60);

    const isKo = store.getters.setting.locale == "ko";

    const clockHour = hour != 0 ? hour + (isKo ? "시간" : "hour") : "";
    const clockMin = min != 0 ? min + (isKo ? "분" : "minutes") : "";
    const clockSec = sec != 0 ? sec + (isKo ? "초" : "seconds") : "0초";

    return clockHour + clockMin + clockSec;
  }

  static calculatePreviousDate(days: number, date?: string): string {
    let criteriaDate = 0;
    if (date) {
      criteriaDate = DateTimeClass.toTimestamp(date) as number;
    } else {
      criteriaDate = DateTimeClass.toTimestamp(new Date()) as number;
    }

    const previousDate = criteriaDate - days * 60 * 60 * 24 * 1000;

    return DateTimeClass.toYYMMDD(previousDate);
  }
}

export default DateTimeClass;
