export const ensure = (promise: Promise<unknown>): void => {
  promise
    .then(() => null)
    .catch((err) => {
      throw err;
    });
};

export const on = async (
  promise: Promise<unknown>
): Promise<Error | unknown> => {
  try {
    return await promise;
  } catch (err) {
    return err;
  }
};
