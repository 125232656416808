





































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import { ApexChartInterface } from "@/components/defaultLayout/dashboard/DashboardBasicInfo.vue";
import DatePicker from "@/components/defaultLayout/util/DatePicker.vue";
import {
  StatisticsDauResponse,
  StatisticsListResponse,
} from "@/generated/adminapi";

@Component({
  components: {
    DatePicker,
    ApexChart,
  },
})
export default class ActiveInfo extends Vue {
  @Prop() dateRange: [string, string];
  @Prop() statisticsListResponse: StatisticsListResponse;

  mountFlag = false;
  date = [];

  mounted(): void {
    this.mountFlag = true;
    this.date = this.dateRange;
  }

  get chartHeight(): number {
    return window.innerHeight * 0.3;
  }

  get apexChart(): ApexChartInterface {
    let data = [];
    if (this.statisticsListResponse.daus) {
      // 시간 순서대로 sort
      const items = Object.keys(this.statisticsListResponse.daus).map(
        (value) => {
          return [value, this.statisticsListResponse.daus[value]];
        }
      );

      items.sort(
        (
          first: [number, StatisticsDauResponse],
          second: [number, StatisticsDauResponse]
        ) => {
          return first[1].historyCTS - second[1].historyCTS;
        }
      );

      const sortData = items.map((value) => value[1]);

      // data 에 할당
      data = sortData.map((data) => {
        return {
          x: this.$_haii.datetime.toYYMMDD(data.historyCTS),
          y: data.dau,
        };
      });
    }

    return {
      series: [
        {
          name: "Inflation",
          data: data,
        },
      ],
      options: {
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },

        dataLabels: {
          enabled: true,
          // formatter: function (val) {
          //   return val;
          // },
          offsetY: -30,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        yaxis: {
          max: 8,
          labels: {
            formatter(val: number, opts?: any): string | string[] {
              return Math.round(val) + "";
            },
          },
        },
      },
    };
  }

  dataHandle(value: any[]): any[] {
    if (!value) return [""];
    return value;
  }

  async onSearch(): Promise<void> {
    try {
      this.$emit("fetchData", this.date);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }
}
