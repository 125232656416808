import * as Oazapfts from "oazapfts/lib/runtime";
import store from "@/store";
import datetime from "@/plugins/haii/datetime";
import * as adminAPI from "@/generated/adminapi/index";
import {
  CompanyListResponse,
  CompanyRequest,
  ContentRequest,
  CouponListResponse,
  CouponRequest,
  CouponResponse,
  GameGroupListResponse,
  GameGroupRequest,
  GameGroupResponse,
  GameListRequest,
  GameListResponse,
  GenericResponse,
  GraphicResponse,
  HistoryExcelUploadRequest,
  HistoryExcelUploadResponse,
  HistoryListResponse,
  HistoryLogListResponse,
  HistoryResponse,
  StatisticsListResponse,
  UserListResponse,
  UserRequest,
  UserResponse,
  v1AdminListCompany,
  v1AdminListCompanyName,
  VoiceListResponse,
} from "@/generated/adminapi/index";
import { institutionListQueryType } from "@/views/institutions/InstitutionList.vue";
import { GetHistoriesInterface } from "@/types/objectType";

interface v1AdminListHistoryQueryInterface {
  filter: string;
  sorting: string;
  pagination: string;
}

export type serverType = "azt" | "admin";

export default class ApiHelperClass {
  readonly baseUrl: string;

  constructor(api: serverType) {
    this.baseUrl = process.env.VUE_APP_ADMIN_SERVER_URL;
  }

  private get requestOptions(): Oazapfts.RequestOpts {
    return {
      baseUrl: this.baseUrl,
      headers: {
        "X-ADMIN-ID": store.state.admin.uid,
      },
    };
  }

  static query(
    Query: GetHistoriesInterface,
    baseUrl?: string
  ): v1AdminListHistoryQueryInterface {
    return {
      filter: JSON.stringify({
        startDate: Query.filter?.date ? Query.filter?.date[0] : undefined,
        endDate: Query.filter?.date ? Query.filter?.date[1] : undefined,
        userKey: Query.filter?.userKey
          ? Query.filter?.userKey.trim()
          : undefined,
        phone: Query.filter?.phone
          ? Query.filter.phone.trim().replaceAll("-", "")
          : undefined,
        name: Query.filter?.name ? Query.filter?.name.trim() : undefined,
        serverUrl: baseUrl,
        companyName: Query.filter?.companyName
          ? Query.filter.companyName.trim()
          : undefined,
        code: Query.filter?.code ? Query.filter.code.trim() : undefined,
        userFlag: Query.filter.userFlag ?? undefined,
      }),
      sorting: JSON.stringify({
        keys: ["_cts"],
        dirs: Query.dataOptions?.sortDesc[0] ? ["DESC"] : ["ASC"],
      }),
      pagination: JSON.stringify({
        limit: Query.dataOptions?.itemsPerPage,
        page: Query.dataOptions?.page,
      }),
    };
  }

  async v1AdminListHistory(
    Query: GetHistoriesInterface
  ): Promise<HistoryListResponse | string> {
    try {
      const res = await adminAPI.v1AdminListHistory(
        ApiHelperClass.query(Query, this.baseUrl),
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data as HistoryListResponse;
    } catch (e) {
      return e;
    }
  }

  async v1AdminRetrieveHistory(
    historyUUID: string
  ): Promise<HistoryResponse | string> {
    try {
      const res = await adminAPI.v1AdminRetrieveHistory(
        historyUUID,
        this.requestOptions
      );
      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }
      if (process.env.VUE_APP_ENV != "test") {
        if (res.data.historyLog.total != 0) {
          res.data.historyLog.historyLogs.map(
            (value) => (value.detail = JSON.parse(value.detail))
          );
        }
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminGraphicEyeTracking(
    ContentRequest: ContentRequest
  ): Promise<GraphicResponse | string> {
    try {
      const res = await adminAPI.v1AdminContentEyeTracking(
        ContentRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListHistoryExcel(
    Query: GetHistoriesInterface
  ): Promise<Blob | string> {
    try {
      console.log(Query);
      const res = await adminAPI.v1AdminListHistoryExcel(
        ApiHelperClass.query(Query, this.baseUrl),
        this.requestOptions
      );

      if (res.status != 200) {
        return "418 ERROR";
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminContentVoices(
    contentRequest: ContentRequest
  ): Promise<VoiceListResponse | string> {
    try {
      const res = await adminAPI.v1AdminContentVoices(
        contentRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminHistoryExcelUpload(
    historyExcelUploadRequest: HistoryExcelUploadRequest
  ): Promise<HistoryExcelUploadResponse | string> {
    try {
      const res = await adminAPI.v1AdminHistoryExcelUpload(
        historyExcelUploadRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListUser(
    Query: GetHistoriesInterface
  ): Promise<UserListResponse | string> {
    try {
      const res = await adminAPI.v1AdminListUser(
        ApiHelperClass.query(Query, this.baseUrl),
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminRetrieveUser(userUuid: string): Promise<UserResponse | string> {
    try {
      const res = await adminAPI.v1AdminRetrieveUser(
        userUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminModifyUser(
    userUuid: string,
    userRequest: UserRequest
  ): Promise<UserResponse | UserListResponse | string> {
    try {
      const res = await adminAPI.v1AdminModifyUser(
        userUuid,
        userRequest,
        this.requestOptions
      );
      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminModifyHistory(
    historyUuid: string,
    userRequest: UserRequest
  ): Promise<GenericResponse | string> {
    try {
      const res = await adminAPI.v1AdminModifyHistory(
        historyUuid,
        userRequest,
        this.requestOptions
      );
      if (res.status != 200 || res.data.code != 201) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminMergeUser(
    userUuid: string,
    updateUserUUID: string
  ): Promise<GenericResponse | string> {
    try {
      const res = await adminAPI.v1AdminMergeUser(
        userUuid,
        {
          filter: JSON.stringify({
            updateUserUUID: updateUserUUID,
          }),
        },
        this.requestOptions
      );
      if (res.status != 200 || res.data.code != 201) {
        return res.data.code + "---" + res.data.message;
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListGameGroup(): Promise<GameGroupListResponse | string> {
    try {
      const res = await adminAPI.v1AdminListGameGroup(this.requestOptions);

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListGame(groupUuid: string): Promise<GameListResponse | string> {
    try {
      const res = await adminAPI.v1AdminListGame(
        groupUuid,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminModifyGame(
    groupUuid: string,
    gameListRequest: GameListRequest
  ): Promise<GameListResponse | string> {
    try {
      const res = await adminAPI.v1AdminModifyGame(
        groupUuid,
        gameListRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminRetrieveUserHistory(
    userUuid: string
  ): Promise<HistoryLogListResponse | string> {
    try {
      const res = await adminAPI.v1AdminRetrieveUserHistory(
        userUuid,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      if (process.env.VUE_APP_ENV != "test") {
        if (res.data.total != 0) {
          res.data.historyLogs.map(
            (value) => (value.detail = JSON.parse(value.detail))
          );
        }
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminCreateCompany(
    companyRequest: CompanyRequest
  ): Promise<GenericResponse | string> {
    try {
      const res = await adminAPI.v1AdminCreateCompany(
        companyRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListCompanies(
    institutionListQueryType: institutionListQueryType
  ): Promise<CouponListResponse | string> {
    try {
      const query = {
        filter: JSON.stringify({
          companyName: institutionListQueryType.filter.institutionName
            ? institutionListQueryType.filter.institutionName
            : undefined,
          code: institutionListQueryType.filter.couponCode
            ? institutionListQueryType.filter.couponCode
            : undefined,
        }),
        sorting: JSON.stringify({
          keys: ["_cts"],
          dirs: institutionListQueryType.dataOptions?.sortDesc[0]
            ? ["DESC"]
            : ["ASC"],
        }),
        pagination: JSON.stringify({
          limit: institutionListQueryType.dataOptions?.itemsPerPage,
          page: institutionListQueryType.dataOptions?.page,
        }),
      };

      const res = await adminAPI.v1AdminListCompany(query, this.requestOptions);

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminRetrieveCompany(
    couponUuid: string
  ): Promise<CouponResponse | string> {
    try {
      const res = await adminAPI.v1AdminRetrieveCompany(
        couponUuid,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListCompaniesName(): Promise<CompanyListResponse | string> {
    try {
      const res = await adminAPI.v1AdminListCompanyName(this.requestOptions);

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminModifyCompany(
    couponUuid: string,
    couponRequest: CouponRequest
  ): Promise<CouponResponse | GenericResponse | string> {
    try {
      const res = await adminAPI.v1AdminModifyCompany(
        couponUuid,
        couponRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminCreateGameGroup(
    gameGroupRequest: GameGroupRequest
  ): Promise<GameGroupResponse | string> {
    try {
      const res = await adminAPI.v1AdminCreateGameGroup(
        gameGroupRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminListStatistics(
    dateRange: [string, string]
  ): Promise<StatisticsListResponse | string> {
    try {
      const res = await adminAPI.v1AdminListStatistics(
        {
          filter: JSON.stringify({
            dateRange: dateRange.map((date) => {
              if (!date) return datetime.toYYMMDD(new Date());
              return date;
            }),
          }),
        },
        this.requestOptions
      );

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }

  async v1AdminRetrieveValidation(filter: {
    email?: string;
    code?: string;
  }): Promise<GenericResponse | string> {
    try {
      const res = await adminAPI.v1AdminRetrieveValidation(
        { filter: JSON.stringify(filter) },
        this.requestOptions
      );

      if (res.status != 200 && res.data.code == 409) {
        return res.data;
      }

      if (res.status != 200) {
        return res.data.code + "---" + res.data.message;
      }

      return res.data;
    } catch (e) {
      return e;
    }
  }
}
