import { metaInterface, RootState, settingInterface, state } from "./state";
import { GetterTree } from "vuex";

export const getters: GetterTree<typeof state, RootState> = {
  setting: (state: RootState): settingInterface => state.setting,
  getBannerState: (state: RootState): boolean => state.bannerState,
  getMetaTitle: (state: RootState): metaInterface => state.metaTag,
};

export type Getters = typeof getters;
