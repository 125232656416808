import { setUserId, logEvent } from "firebase/analytics";
import { analytics } from "@/plugins/firebase/firebase";

analytics.app.automaticDataCollectionEnabled = true;

class firebaseAnalytics {
  static setFirebaseUserId = (userId: string) => {
    setUserId(analytics, userId);
  };

  static loginEvent = (method: string) => {
    logEvent(analytics, "login", {
      method: method,
    });
  };

  static createEvent = (activeEvent: string, createObject: any) => {
    logEvent(analytics, "haii_create_content", {
      active_event: activeEvent,
      create_object: JSON.stringify(createObject),
    });
  };

  static readEvent = (
    activeEvent: string,
    readObject?: any,
    query?: any,
    note?: string
  ) => {
    logEvent(analytics, "haii_read_content", {
      active_event: activeEvent,
      read_object: readObject ? JSON.stringify(readObject) : undefined,
      filter: query ? JSON.stringify(query) : undefined,
      note: note ? note : undefined,
    });
  };

  static updateEvent = (
    activeEvent: string,
    originObject?: any,
    changedObject?: any
  ) => {
    logEvent(analytics, "haii_update_content", {
      active_event: activeEvent,
      origin_object: originObject ? JSON.stringify(originObject) : undefined,
      changed_object: changedObject ? JSON.stringify(changedObject) : undefined,
    });
  };

  static errorEvent = (errorMessage: string) => {
    logEvent(analytics, "haii_error", {
      error_message: errorMessage,
    });
  };
}

export default firebaseAnalytics;
