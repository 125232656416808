import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { RootState, state } from "@/store/state";
import { mutations, MutationTypes } from "@/store/mutations";
import { actions } from "@/store/actions";
import { getters } from "@/store/getters";

Vue.use(Vuex);

// 새로고침을 해도 데이터를 유지하기 위함
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  // state 중 유지할 데이터만 선별
  reducer: (state: RootState) => ({
    admin: state.admin,
    bannerState: state.bannerState,
    metaTag: state.metaTag,
  }),

  // mutation 중 변경사항 반영할 state 가 존재하는 경우, 만약 기본적으론 모든 mutation이 true 로 반영
  filter: (mutations): boolean => {
    switch (mutations.type) {
      case MutationTypes.ADMIN_SET:
        return true;
      case MutationTypes.SET_BANNER_STATE:
        return true;
      case MutationTypes.SET_META_TITLE:
        return true;
      default:
        break;
    }
    return false;
  },
});

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,

  reducer: (state: RootState) => ({
    setting: state.setting,
  }),

  // mutation 중 변경사항 반영할 state 가 존재하는 경우, 만약 기본적으론 모든 mutation이 true 로 반영
  filter: (mutations): boolean => {
    switch (mutations.type) {
      case MutationTypes.SETTING_SET:
        return true;
      default:
        break;
    }
    return false;
  },
});

export default new Vuex.Store({
  plugins: [vuexSession.plugin, vuexLocal.plugin],
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
});
